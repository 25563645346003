export default {
  computed: {
    nomesColunasDinamicas() {
      return this.configuracao.colunasDinamicasLinha;
    },
    nomesColunasPadrao() {
      return this.colunasPadrao.map((coluna) => coluna.field);
    },
    nomesColunasExtensao() {
      return this.configuracao.colunasExtensaoLinha;
    },
  },
  data() {
    return {
      datasource: [],

      linhaInicial: 2,
      rowsNumber: 50,
      ultimaLinhaEscrita: 0,

      // relacao de nomes de colunas por chaves validas
      controleColunas: {
        label: {},
        hash: {},
      },
      controleColunasDivisoes: [],
      colunasPadrao: [
        {
          field: 'descricao_linha',
          title: `${this.$t('label.linha')}`,
          disabled: true,
        },
        {
          field: 'tipo_investimento',
          title: `${this.$t('label.tipo_de_investimento')}`,
          disabled: true,
        },
        {
          field: 'centro_custo',
          title: this.$tc('label.centro_custo', 1),
          disabled: true,
        },
        {
          field: 'produto',
          title: this.$tc('label.produto', 1),
          disabled: true,
        },
        {
          field: 'cliente',
          title: this.$tc('label.cliente', 1),
          disabled: true,
        },
        {
          field: 'posseConta',
          title: this.$tc('label.divisao', 1),
          disabled: true,
        },
      ],
      colunas: [],
      colunasPeriodos: [],
      colunasFixas: ['descricao_linha', 'tipo_investimento', 'posseConta'],
      colunasExibicao: [],
    };
  },
  methods: {
    converteLinha(linha) {
      const chaves = Object.keys(linha),
        convertida = {};

      chaves.forEach((chave) => {
        if (this.deParaCampos[chave] != null) {
          convertida[this.deParaCampos[chave]] = linha[chave];
        } else {
          convertida[chave] = linha[chave];
        }
      }, this);

      return convertida;
    },
    preencheDesdobramentosLinha(linhaPrincipal, linhasIntermediarias) {
      const linhaComprometido = {},
        linhaSaldo = {},
        linhaAdendo = {},
        linhaReducao = {},
        linhaAporte = {},
        linhaPercentualAplicado = {},
        linhaEstorno = {};

      const { desdobramentos } = linhaPrincipal;
      desdobramentos.forEach((desdobramento) => {
        const periodo = this.controleColunas.label[desdobramento.periodo];
        linhaPrincipal[periodo] = desdobramento.planejado
          + (desdobramento.adendo - desdobramento.reducao - desdobramento.estorno);
        linhaAdendo[periodo] = desdobramento.adendo;
        linhaReducao[periodo] = desdobramento.reducao;
        linhaComprometido[periodo] = desdobramento.comprometido;
        linhaSaldo[periodo] = desdobramento.saldo;
        linhaEstorno[periodo] = desdobramento.estorno;
        linhaAporte[periodo] = 0;
        linhaPercentualAplicado[periodo] = desdobramento.percentualAplicado;
      }, this);

      linhaAdendo.posseConta = this.$tc('label.adendo', 1);
      linhaReducao.posseConta = this.$tc('label.reducao', 1);
      linhaComprometido.posseConta = this.$tc('label.comprometido', 1);
      linhaEstorno.posseConta = this.$tc('label.estorno', 1);
      linhaSaldo.posseConta = this.$tc('label.saldo', 1);
      linhaAporte.posseConta = this.$tc('label.valor_aportar', 1);
      linhaPercentualAplicado.posseConta = this.$tc('label.percentual_sellin_aplicado', 1);

      if (this.configuracao.rateioDivisao) {
        linhasIntermediarias.push(linhaPercentualAplicado);
      }
      linhasIntermediarias.push(linhaAdendo);
      linhasIntermediarias.push(linhaReducao);
      linhasIntermediarias.push(linhaComprometido);
      linhasIntermediarias.push(linhaEstorno);
      linhasIntermediarias.push(linhaSaldo);
      if (this.configuracao.permiteVerbaParcial) {
        linhasIntermediarias.push(linhaAporte);
      }
    },
    preencheDesdobramentosRateio(itemRateio, linhasIntermediarias) {
      const linhaRateio = {},
        linhaComprometido = {},
        linhaSaldo = {},
        linhaPlanejado = {},
        linhaAdendo = {},
        linhaReducao = {},
        linhaEstorno = {};

      const { desdobramentos, posseConta } = itemRateio;
      desdobramentos.forEach((desdobramento) => {
        const periodo = this.controleColunas.label[desdobramento.periodo];
        linhaRateio[periodo] = desdobramento.planejado;
        linhaAdendo[periodo] = desdobramento.adendo;
        linhaReducao[periodo] = desdobramento.reducao;
        linhaPlanejado[periodo] = desdobramento.planejado;
        linhaComprometido[periodo] = desdobramento.comprometido;
        linhaSaldo[periodo] = desdobramento.saldo;
      }, this);

      linhaRateio.posseConta = posseConta;
      linhaAdendo.posseConta = this.$tc('label.adendo', 1);
      linhaReducao.posseConta = this.$tc('label.reducao', 1);
      linhaPlanejado.posseConta = this.$tc('label.planejado', 1);
      linhaComprometido.posseConta = this.$tc('label.comprometido', 1);
      linhaEstorno.posseConta = this.$tc('label.estorno', 1);
      linhaSaldo.posseConta = this.$tc('label.saldo', 1);

      linhasIntermediarias.push(linhaRateio);
      linhasIntermediarias.push(linhaAdendo);
      linhasIntermediarias.push(linhaReducao);
      linhasIntermediarias.push(linhaPlanejado);
      linhasIntermediarias.push(linhaComprometido);
      linhasIntermediarias.push(linhaEstorno);
      linhasIntermediarias.push(linhaSaldo);
    },
    preencheRateios(linhaPrincipal, linhasIntermediarias) {
      linhaPrincipal.rateios.forEach((itemRateio) => {
        this.preencheDesdobramentosRateio(itemRateio, linhasIntermediarias);
      });
    },
    parseCampoBoolean(linha, hash) {
      const valor = linha[hash];
      linha[hash] = valor === true
        ? 'SIM' : 'NAO';
    },
    parseLinha(linha) {
      const { mapaCamposDinamicos } = this.metadados;
      const labels = Object.keys(linha);
      labels.forEach((label) => {
        const hash = this.controleColunas.label[label];

        if (this.ehColunaDinamica(label)) {
          linha[hash] = linha[label];
          delete linha[label];

          const campo = mapaCamposDinamicos[label];
          if (campo.tipoCampo === 'BOOLEAN') {
            this.parseCampoBoolean(linha, hash);
          }
        }
      });
      return linha;
    },
    preencheLinha(linha) {
      const linhasIntermediarias = [];

      const linhaPrincipal = { ...this.parseLinha(linha) };
      linhaPrincipal.principal = true;
      linhasIntermediarias.push(linhaPrincipal);

      this.preencheDesdobramentosLinha(linhaPrincipal, linhasIntermediarias);
      this.preencheRateios(linhaPrincipal, linhasIntermediarias);

      return linhasIntermediarias;
    },
    elaboraFieldSeguro(label) {
      let hash = this.controleColunas.label[label];
      if (hash != null) {
        return hash;
      }

      const charset = 'abcdefghijklmnopqrstuvwxyz',
        len = 10;
      hash = '';
      for (let i = 0; i < len; i += 1) {
        hash
        += charset.charAt(Math.floor(Math.random() * charset.length));
      }

      this.controleColunas.hash[hash] = label;
      this.controleColunas.label[label] = hash;

      return hash;
    },
    montaColunaPeriodo(periodo) {
      return {
        disabled: true,
        field: this.elaboraFieldSeguro(periodo),
        title: `${periodo}`,
        type: 'DINHEIRO',
        colunaPeriodo: true,
      };
    },
    montaColunaTotal() {
      return {
        disabled: true,
        field: 'total',
        title: this.$tc('label.total', 1),
        type: 'DINHEIRO',
      };
    },
    montaColunaExtensao(definicaoMetadado) {
      const titulo = definicaoMetadado.entidadeEstrangeira,
        { label } = definicaoMetadado;
      return {
        field: label,
        title: titulo,
        disabled: true,
      };
    },
    montaColunaDinamica(definicaoMetadado) {
      const {
        labelCampo,
        nomCampo,
      } = definicaoMetadado;
      const coluna = {
        field: this.elaboraFieldSeguro(labelCampo),
        title: nomCampo,
        disabled: true,
      };
      return coluna;
    },
    montaColunaPadrao(nomeCol) {
      return this.colunasPadrao
        .filter((coluna) => coluna.field === nomeCol)[0];
    },
    nomesColunasPeriodo() {
      const { linhasInvestimento } = this.rateio;
      return linhasInvestimento[0].desdobramentos.map((d) => d.periodo);
    },
    nomesColunasNaoFixas() {
      const { colunasLinhaInvestimento } = this.configuracao;
      return colunasLinhaInvestimento
        .filter((c) => this.colunasFixas.indexOf(c) < 0)
        .filter((c) => this.configuracao.colunaPosseConta !== c);
    },
    nomesColunasOrdenadas() {
      const cols = [];
      cols.push(
        'descricao_linha',
        'tipo_investimento',
        ...this.nomesColunasNaoFixas(),
        'posseConta',
        ...this.nomesColunasPeriodo(),
        'total',
      );
      return cols;
    },
    ehColunaPeriodo(nomeCol) {
      return this.nomesColunasPeriodo().indexOf(nomeCol) >= 0;
    },
    ehColunaPadrao(nomeCol) {
      return this.nomesColunasPadrao.indexOf(nomeCol) >= 0;
    },
    ehColunaExtensao(nomeCol) {
      return this.nomesColunasExtensao.indexOf(nomeCol) >= 0;
    },
    ehColunaDinamica(nomeCol) {
      return this.nomesColunasDinamicas.indexOf(nomeCol) >= 0;
    },
    ehColunaTotal(nomeCol) {
      return nomeCol === 'total';
    },
    montaColunas() {
      const nomesColunas = this.nomesColunasOrdenadas();
      nomesColunas.forEach((nomeCol) => {
        let coluna = null;
        if (nomeCol === 'posseConta') {
          coluna = this.montaColunaPadrao(nomeCol);
          coluna.title = this.$tc(`label.${this.configuracao.colunaPosseConta}`, 1);
          this.colunasExibicao.push(coluna);
        } else if (this.ehColunaPadrao(nomeCol)) {
          coluna = this.montaColunaPadrao(nomeCol);
          this.colunasExibicao.push(coluna);
        } else if (this.ehColunaExtensao(nomeCol)) {
          const { mapaEntidades } = this.metadados;
          const definicaoMetadado = mapaEntidades[nomeCol];
          coluna = this.montaColunaExtensao(definicaoMetadado);
          this.colunasExibicao.push(coluna);
        } else if (this.ehColunaPeriodo(nomeCol)) {
          coluna = this.montaColunaPeriodo(nomeCol);
          this.colunasPeriodos.push(coluna);
        } else if (this.ehColunaTotal(nomeCol)) {
          coluna = this.montaColunaTotal();
        } else {
          const { mapaCamposDinamicos } = this.metadados;
          const definicaoMetadado = mapaCamposDinamicos[nomeCol];
          coluna = this.montaColunaDinamica(definicaoMetadado);
          this.colunasExibicao.push(coluna);
        }
        this.colunas.push(coluna);
      });
    },
    preencheDados(linhas) {
      linhas.forEach((linhaInvestimento) => {
        const linhasSpreadsheet = this.preencheLinha(linhaInvestimento, true);
        linhasSpreadsheet.forEach((linhaSpreadsheet) => this.datasource.push(linhaSpreadsheet));
      });

      this.ultimaLinhaEscrita = this.datasource.length + 1;
    },
    calculaQtdLinhasSpreadsheet() {
      let linhas = 0;
      for (let i = 0; i < this.rateio.linhasInvestimento.length; i += 1) {
        linhas += 1;
        if (this.rateio.linhasInvestimento[i].rateios
          && this.rateio.linhasInvestimento[i].rateios.length) {
          linhas += this.rateio.linhasInvestimento[i].rateios.length;
        }
      }
      this.rowsNumber = linhas > 7 ? (linhas * 6) + 5 : 50;
    },
  },
  beforeMount() {
    this.calculaQtdLinhasSpreadsheet();
  },
};
